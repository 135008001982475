import alfajiri5 from  "../../assets/images/alfajiri-5.jpg"
function AlfajiriInfoOne() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout1 mt--0 mb--0"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--170 col--3"
          style={{ opacity: 1, visibility: "visible" }}
        >
          <span
            style={{
              marginTop: "-1rem",
              fontSize: "30px",
              fontWeight: "500",
              color: "black",
              visibility: "visible",
            }}
          >
            Choose a builder based on what they've built
          </span>
          <p>
            I reached out to my trusty architect and together, we developed the
            vision over a couple of months. It ended up being a 4 apartment
            complex with 1 studio, 2 3 bedroom apartments and 1 4 bedroom
            apartment.
          </p>
          <p>
            The next step I needed to figure out is who would lead the actual
            construction. I drove around some of my favourite neighbourhoods in
            Nairobi checking out recent developments. I visited a housing
            complex comprising modern, elegant townhouses. These had been built
            in the exact style that I wanted my house to be built in. I made
            some enquiries and soon had the number of the foreman that had been
            in charge of the construction process there. Long story short, I
            called him, met him at my site and we agreed terms for him to lead
            my construction. ?
          </p>
        </div>
        <div className="imageText__column col--3">
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${alfajiri5})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default AlfajiriInfoOne;
