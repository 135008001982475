import "./baraka.css";

function SectionTwoBaraka() {
  return (
    <section
      data-scroll-section=""
      className="textOnly textOnly--split50 mt--0 mb--180"
    >
      <div
        data-scroll
        data-scroll-call="textOnly"
        className="textOnly__container container flex flex--y-start"
      >
        <div className="textOnly__header col--3">
          <span
            className="textOnly__icon"
            style={{
              marginBottom: "20px",
              fontWeight: "bolder",
            }}
          >
            The Story
          </span>
          <h3
            className="textOnly__text fs--18 fc--grey fw--400 lh--180"
            style={{
              opacity: 1,
              visibility: "visible",
            }}
          >
            <p>
              In 2019 I set out to build a home upcountry. The decision to build
              was driven by two things:
            </p>
            <p>
              The first was tough circumstances. My mother, having been a
              business person all her life, had run into business challenges.
            </p>
          </h3>
        </div>
        <div className="textOnly__column col--3">
          <div
            className="textOnly__text fs--18 fc--grey fw--300 lh--180"
            style={{ opacity: 1, visibility: "visible" }}
          >
            <p>
              Her entire business was collapsing, we tried to keep it afloat but
              after a few years of trying, we realized we weren't making any
              headway. The impact of this failure was made even worse by her
              emerging health complications, we realized it just wasn't working
              out.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionTwoBaraka;
