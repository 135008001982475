import { useState, useEffect, Fragment } from "react";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import mpesaImg from "../assets/mpesa.jpg";
import paypalImg from "../assets/paypal.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  buyCreation,
  callbackService,
  productsEndpt,
  validateEmail,
} from "../services/create";
import axios from "axios";
import Paypal from "./Paypal";
ReactModal.setAppElement(document.getElementById("root"));

export default function Modal({
  handleCloseModal,
  showModal,
  productId,
  productName,
  amount,
}) {
  const [mpesaChecked, setMpesaChecked] = useState(true);
  const [paypalChecked, setPaypalChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sucess, setSucess] = useState("");
  const [error, setError] = useState("");
  const [mjengoProducts, setMjengoProducts] = useState([]);

  //For new Selection
  const [productid, setProductid] = useState(productId);
  const [productname, setProductname] = useState(productName);
  const [newamount, setNewamount] = useState(amount);

  // paypal success and error
  const [paypalSuccess, setPaypalSuccess] = useState("");
  const [paypalError, setPaypalError] = useState("");

  const updatePaypalSuccess = (success) => setPaypalSuccess(success);
  const updatePaypalError = (error) => setPaypalError(error);

  // fetch mjengo products function
  const fetchMjengoProducts = async () => {
    const { data } = await axios.get(productsEndpt);
    setMjengoProducts(data.data[0]);
  };

  useEffect(() => {
    fetchMjengoProducts();
  }, []);

  // change payment method
  const handleMpesaClick = () => {
    if (!mpesaChecked) {
      setMpesaChecked(true);
      setPaypalChecked(false);
    }
  };
  const handlePaypalClick = () => {
    if (!paypalChecked) {
      setPaypalChecked(true);
      setMpesaChecked(false);
    }
  };

  let customContentStyle = {
    width: 850,
    margin: "100px auto",
    background: "trasparent",
    border: "none",
  };
  const { width } = useWindowDimensions();
  if (width < 900) {
    customContentStyle.width = "unset";
    customContentStyle.padding = "0rem";
    customContentStyle.margin = "0px auto";
    customContentStyle.backgroundImage = "none";
  }

  const { register, handleSubmit } = useForm();
  const { register: registerPaypal, handleSubmit: handleSubmitPaypal } =
    useForm();

  const [paypalData, setPaypalData] = useState();
  const [showPaypalBtn, setShowPaypalBtn] = useState(false);
  const onSubmitPaypal = (data) => {
    if (!validateEmail(data.email))
      return setError("Email must be a google mail (gmail)");
    setPaypalData(data);
    localStorage.setItem("paypal", JSON.stringify(data));
    setShowPaypalBtn(true);
  };

  const handleSelectChange = (value) => {
    setProductid(value);
    const newProduct = mjengoProducts.find((p) => p.id === +value);

    setProductname(newProduct.title);
    setNewamount(newProduct.price);
  };

  const handleBuy = async (data) => {
    if (!validateEmail(data.email))
      return setError("Email must be a google mail (gmail)");
    data.product_id = productid ? +productid : productId;
    data.amount = newamount ? newamount : amount;
    data.description = productname ? productname : productName;
    if (mpesaChecked) data.payment_method = "Mpesa";

    try {
      setLoading(true);
      const { data: responseData } = await buyCreation(data);
      setSucess(
        "Processing your request, please wait. Notification sent to your phone, Enter your PIN to complete your request"
      );
      setLoading(false);
      let mpesaCallBack = function () {
        let intr = setInterval(async function () {
          const { data: mpesaResponse } = await callbackService({
            checkout_id:
              responseData.data.original.mpesaRequest[0].CheckoutRequestID,
            user_id: responseData.data.original.user.id,
          });
          if (mpesaResponse.success === false) {
            setSucess(mpesaResponse.message);
          }
          if (mpesaResponse.success === true) {
            setSucess(mpesaResponse.message);
            clearInterval(intr);
          }
        }, 10000);
      };
      mpesaCallBack();
    } catch (error) {
      setSucess("");
      setLoading(true);
      setError("Sorry, We could not process your request");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  // Exchange Rates
  const [exchangeRate, setExchangeRate] = useState(1);

  const fetchExchanges = async () => {
    const res = await axios.get(
      `https://openexchangerates.org/api/latest.json?app_id=7dd942a543b24b09a1501d2394c7c6a7`
    );
    setExchangeRate(res?.data?.rates?.KES);
  };

  useEffect(() => {
    fetchExchanges();
  }, []);

  return (
    <div className="modal-custom">
      <ReactModal
        isOpen={showModal}
        contentLabel={"Payment Method"}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(20, 20, 20, 0.8)",
            zIndex: 1000,
          },
          content: customContentStyle,
          width: "unset",
        }}
      >
        <div className="modal-inner">
          <div className="modal-nav">
            <div
              className={
                mpesaChecked
                  ? "modal-nav__item modal-nav__item-active first-active"
                  : "modal-nav__item"
              }
              onClick={handleMpesaClick}
            >
              <input
                type="checkbox"
                name="mpesa"
                id="mpesa"
                readOnly
                checked={mpesaChecked}
              />
              <h3>Mpesa</h3>
            </div>
            <div
              className={
                paypalChecked
                  ? "modal-nav__item modal-nav__item-active second-active"
                  : "modal-nav__item"
              }
              onClick={handlePaypalClick}
            >
              <input
                type="checkbox"
                name="paypal"
                id="paypal"
                readOnly
                checked={paypalChecked}
              />
              <h3>Paypal</h3>
            </div>
          </div>
          {mpesaChecked && (
            <>
              <div className="modal-cards">
                <p>Make Your payment Securely through you MPESA account</p>
                <figure>
                  <img src={mpesaImg} alt="Mpesa" />
                </figure>
              </div>
              <p style={{ padding: "1rem 2rem" }}>
                Please enter your details in order to get
                <span style={{ margin: "0 5px" }}>
                  {productname ? productname : productName}
                </span>
                with KSH.
                <span style={{ margin: "0 5px" }}>
                  {newamount ? newamount : amount}
                </span>
              </p>
            </>
          )}
          {paypalChecked && (
            <>
              <div className="modal-cards">
                <p>Make Your payment Securely through you PAYPAL account</p>
                <figure>
                  <img src={paypalImg} alt="Mpesa" />
                </figure>
              </div>
              <p style={{ padding: "1rem 2rem" }}>
                Please enter your details in order to get
                <span style={{ margin: "0 5px" }}>
                  {productname ? productname : productName}
                </span>
                with USD.
                <span style={{ margin: "0 5px" }}>
                  {newamount
                    ? ((newamount / exchangeRate) * 1.15).toFixed(2)
                    : ((amount / exchangeRate) * 1.15).toFixed(2)}
                </span>
              </p>
            </>
          )}

          {mpesaChecked && (
            <Fragment>
              <form className="modal-form">
                <div className="modal-form__controller">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="First Name"
                    ref={register({ required: true })}
                    required
                    className="modal-form__controller--input"
                  />
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Last Name"
                    ref={register({ required: true })}
                    required
                    className="modal-form__controller--input"
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    ref={register({ required: true })}
                    required
                    className="modal-form__controller--input"
                  />
                </div>
                <div className="modal-form__controller">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    ref={register({ required: true })}
                    placeholder="Phone Number"
                    required
                    className="modal-form__controller--input"
                  />
                  <select
                    name="product_id"
                    id="product_id"
                    className="modal-form__controller--input"
                    onChange={(e) => handleSelectChange(e.currentTarget.value)}
                  >
                    <option value={productId}>{productName}</option>
                    {mjengoProducts
                      ?.filter((p) => p.id !== productId)
                      .map((mjengoProduct, i) => (
                        <option value={mjengoProduct.id} key={i}>
                          {mjengoProduct.title}
                        </option>
                      ))}
                  </select>
                  <button onClick={handleSubmit(handleBuy)}>
                    {loading ? <span>Loading..</span> : <span>Buy Now</span>}
                  </button>
                </div>
              </form>
              {sucess && <p className="modal-sucess">{sucess}</p>}
              {error && <p className="modal-error">{error}</p>}
            </Fragment>
          )}
          {paypalChecked && (
            <div className="modal-form">
              <div
                className="modal-form__controller"
                style={{ display: !showPaypalBtn ? "flex" : "none" }}
              >
                <input
                  type="text"
                  name="first_name"
                  id="name"
                  placeholder="First Name"
                  ref={registerPaypal({ required: "First Name is required" })}
                  required
                  className="modal-form__controller--input"
                />

                <input
                  type="text"
                  name="last_name"
                  id="name"
                  placeholder="Last Name"
                  ref={registerPaypal({ required: true })}
                  required
                  className="modal-form__controller--input"
                />

                <input
                  type="text"
                  name="phone"
                  id="phone"
                  ref={registerPaypal({ required: true })}
                  placeholder="Phone Number"
                  required
                  className="modal-form__controller--input"
                />
              </div>

              <div
                className="modal-form__controller"
                style={{ display: !showPaypalBtn ? "flex" : "none" }}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  ref={registerPaypal({ required: true })}
                  required
                  className="modal-form__controller--input"
                />
                <button onClick={handleSubmitPaypal(onSubmitPaypal)}>
                  {" "}
                  Proceed to Pay
                </button>
              </div>

              <div
                className="modal-form__controller"
                style={{ display: !showPaypalBtn ? "flex" : "none" }}
              >
                {sucess && <p className="modal-sucess">{sucess}</p>}
                {error && <p className="modal-error">{error}</p>}
              </div>

              <div style={{ display: showPaypalBtn ? "block" : "none" }}>
                <Paypal
                  description={productname ? productname : productName}
                  productId={productId}
                  amount={
                    newamount
                      ? ((newamount / exchangeRate) * 1.15).toFixed(2)
                      : ((amount / exchangeRate) * 1.15).toFixed(2)
                  }
                  amount_ksh={
                    newamount
                      ? (newamount * 1.15).toFixed(2)
                      : (amount * 1.15).toFixed(2)
                  }
                  updatePaypalError={updatePaypalError}
                  updatePaypalSuccess={updatePaypalSuccess}
                  paypalData={paypalData}
                />
                <button
                  onClick={() => setShowPaypalBtn(false)}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    padding: "1rem 0",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    backgroundColor: "burlywood",
                    marginTop: "1rem",
                  }}
                >
                  Back to Details
                </button>
              </div>
              {paypalSuccess && <p className="modal-sucess">{paypalSuccess}</p>}
              {paypalError && <p className="modal-error">{paypalError}</p>}
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
}
