import alfajiri4 from  "../../assets/images/alfajiri-4.jpg"
function AlfajiriSectionSix() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout2 mt--320 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
          <span
            style={{
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
              visibility: "visible",
            }}
          >
            Ok...so what next?
          </span>
          <p>
            After a couple of months of pondering what I wanted to do, I wrote
            down things that I was certain about.
          </p>
          <p>
            1. I wanted to live in the area I had bought my plot. It was really
            peaceful and beautiful and I was looking forward to waking up there
            everyday.
          </p>
          <p>
            2. Based on the trajectory of my career, there was a good chance
            that in 5 years, I may need to move on to a different location.{" "}
          </p>
          <p>
            3. I wanted to be able to maximise the return on my investment if I
            was going to move out.
          </p>
          <p>
            Because of these three reasons, I decided to build a small apartment
            complex instead of a single house on my plot. I figured that I could
            still get a beautiful family house as well as some income generating
            units. In addition to that, if I was to ever move out of the house,
            it wouldn’t be too big or too expensive to find someone to rent it.
            And so, the journey to build began. Alfajiri Apartments was born.
          </p>
          {/* <span
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
            }}
          >
            Penye Nia Pana Njia
          </span> */}
        </div>
        <div className="imageText__column col--3">
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${alfajiri4})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default AlfajiriSectionSix;
