import alfajiri6 from  "../../assets/images/alfajiri-6.jpg"
function AlfajiriInfoTwo() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout2 mt--320 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
          <span
            style={{
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
              visibility: "visible",
            }}
          >
            10 lessons from building a house in Kenya
          </span>
          <p>1. Foundations on a slope can be very slow and expensive.</p>
          <p>
            2. Substructures on a slope require a lot of reinforcement including
            retaining walls that require a lot of steel. We were able to make
            some savings by using concrete blocks for our retaining walls.
          </p>
          <p>
            3. Walling is fast and is probably the most fulfilling part for a
            project owner. Every day you find significant progress! .
          </p>
          <p>
            4. Design decisions can be very expensive. We had initially decided
            that we wanted our doors to be floor to ceilings therefore we
            couldn’t have any down beams in the house. This meant that we had to
            spend a lot of money on slab beams. More than we would have had to
            spend had we allowed our engineers to incorporate down beams.
          </p>
          <p>
            5. Be very careful when selecting Subcontractors. Subcontractors can
            ruin your timelines. My plumbing and steel fabrication
            subcontractors ended up being really slow and inefficient. After
            multiple delays, I had to cancel their contracts and ended up
            incurring heavy losses because I had already paid significant down
            payments to them.
          </p>
        </div>
        <div className="imageText__column col--3">
          <h2 className="imageText__heading fs--40 fw--500 lh--90">
            {/* Get A Plan */}
          </h2>
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${alfajiri6})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default AlfajiriInfoTwo;
