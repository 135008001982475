import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { productsEndpt } from "../services/create";
import Modal from "./Modal";
import pic2 from  "../assets/pic2.jpg"
import img2 from  "../assets/images/alfajiri-2.jpg"

function SectionTwoHome() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();

  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // fetch mjengo products function
  const [mjengoProducts, setMjengoProducts] = useState([]);

  const product = mjengoProducts?.find((p) => p.id === 1);
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };

  useEffect(async () => {
    const { data } = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        productId={productId}
        productName={productName}
        amount={amount}
      />
      <section
        data-scroll-section=""
        className="doubleImage bgWhite mt--200 mb--280"
      >
        <div className="doubleImage__container container flex flex--y-center custom-container">
          <div className="doubleImage__textCol col--3 custom-col">
            <div
              data-scroll
              data-scroll-call="doubleImage"
              className="doubleImage__textHolder"
            >
              <h2 className="doubleImage__header fs--48 fw--500 lh--150">
                Track Progress.
                <br />
                Save Money. <br />
                Be In Charge.
                <br />
              </h2>

              <div className="doubleImage__text fs--18 fc--grey fw--300 lh--180 ">
                Build A Home provides an easy to use construction tracker that
                enables you to track every aspect of your home construction
                project. This is Mjengo Tracker.
              </div>
              <div className="linkArrow doubleImage__link ">
                <a
                  className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel"
                  onClick={() =>
                    handleShowModal(product.id, product.title, product.price)
                  }
                >
                  <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <p className="linkArrow__label fs--16 fc--orange fw--500">
                    Get {product?.title}
                  </p>
                  <div className="linkArrow__arrowHolder">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <div className="linkArrow__lines">
                    <div className="linkArrow__overflow flex flex--x-between">
                      <div className="linkArrow__line"></div>
                      <div className="linkArrow__line o--3"></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="doubleImage__imgCol col--3 custom-col">
            <div className="doubleImageMedia flex flex--y-end pos--rel">
              <div className="doubleImageMedia__portraitHolder pos--rel z--1">
                <div
                  data-scroll
                  data-scroll-speed="0.5"
                  data-scroll-offset="0"
                  className="doubleImageMedia__img pos--abs"
                  style={{
                    backgroundImage:
                    `url(${pic2})`,
                  }}
                ></div>
              </div>
              <div className="doubleImageMedia__landscapeHolder pos--rel z--2">
                <div
                  data-scroll
                  data-scroll-speed="-0.5"
                  data-scroll-offset="0"
                  className="doubleImageMedia__img pos--abs"
                  style={{
                    backgroundImage:
                      `url(${img2})`,
                  }}
                ></div>
              </div>
              <div
                data-scroll
                data-scroll-speed="2"
                data-scroll-offset="0"
                className="doubleImageMedia__circle pos--abs z--3 doubleImageMedia__circle--peach"
              ></div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SectionTwoHome;
