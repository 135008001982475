import React, { Component } from "react";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { Redirect } from "react-router-dom";
import { postPaypalService } from "../services/create";
class Paypal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    this.createOrder = this.createOrder.bind(this);
  }

  createOrder(payment) {
    let postData = {
      payerID: payment.payerID,
      paymentID: payment.paymentID,
      paymentToken: payment.paymentToken,
      pid: this.props.productId,
    };
    console.log(postData);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/orders"} />;
    }

    const paypalInfo = JSON.parse(localStorage.getItem("paypal"));

    const onSuccess = async (payment) => {
      this.createOrder(payment);

      this.props.updatePaypalSuccess(
        "Transaction Successfully processed, tracker shared, please check your email. Thank you!"
      );
      const payload = {
        paymentID: payment.paymentID,
        product_id: this.props.productId,
        status: "complete",
        amount: this.props.amount,
        amount_ksh: this.props.amount_ksh,
      };
      payload.email = paypalInfo.email;
      payload.first_name = paypalInfo.first_name;
      payload.last_name = paypalInfo.last_name;
      payload.phone = paypalInfo.phone;
      try {
        const res = await postPaypalService(payload);
        if (res.status === 200) {
            window.location.reload();
        }
      } catch (error) {
        this.props.updatePaypalError("We could not process your requst");
      }
    };

    const onCancel = (data) => {
      this.props.updatePaypalError("Transaction was Cancelled");
      const payload = {
        paymentID: data.paymentID,
        product_id: this.props.productId,
        status: "cancel",
        amount: this.props.amount,
        amount_ksh: this.props.amount_ksh,
      };
      payload.email = paypalInfo.email;
      payload.first_name = paypalInfo.first_name;
      payload.last_name = paypalInfo.last_name;
      payload.phone = paypalInfo.phone;
      postPaypalService(payload);
    };

    const onError = (err) => {
      this.props.updatePaypalError("Transaction was Cancelled");
      const payload = {
        paymentID: err.paymentID,
        product_id: this.props.productId,
        status: "failed",
        amount: this.props.amount,
        amount_ksh: this.props.amount_ksh,
      };
      payload.email = paypalInfo.email;
      payload.first_name = paypalInfo.first_name;
      payload.last_name = paypalInfo.last_name;
      payload.phone = paypalInfo.phone;
      postPaypalService(payload);
    };

    let env = "production";
    let currency = "USD";
    let total = this.props.amount;

    const client = {
      production:
        "AUZfQo4LiuOX5M7bIRSBXvEgTsNKUalBOcDubv6-BIMzFfqDJLfusLRPO0T5pf8P0rkl00U-6m278ldN",
      // sandbox:
      //   "AfUbzx2yXW_ciyLvUrpZonSxoYqfTVpnyu86l95gScxFSU7zQGl1Zqby9uFmJNSqv31hqT1jlC9f-gMk",
    };

    return (
      <PaypalExpressBtn
        env={env}
        client={client}
        currency={currency}
        total={total}
        onError={onError}
        onSuccess={onSuccess}
        onCancel={onCancel}
        style={{
          size: "responsive",
          color: "gold",
          shape: "rect",
          label: "checkout",
        }}
      />
    );
  }
}

export default Paypal;
