import { Fragment } from "react";
import AlfajiriSectionOne from "../components/Alfajiri/AlfajiriSectionOne";
import AlfajiriSectionFive from "../components/Alfajiri/AlfajiriSectionFive";
import AlfajiriSectionFour from "../components/Alfajiri/AlfajiriSectionFour";
import AlfajiriSectionSeven from "../components/Alfajiri/AlfajiriSectionSeven";
import AlfajiriSectionSix from "../components/Alfajiri/AlfajiriSectionSix";
import AlfajiriSectionThree from "../components/Alfajiri/AlfajiriSectionThree";
import AlfajiriVideo from "../components/Alfajiri/AlfajiriVideo";
import AlfajiriSectionTwo from "../components/Alfajiri/AlfajiriSecionTwo";
import Specification from "../components/Alfajiri/Specification";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AlfajiriInfoOne from "../components/Alfajiri/AlfajiriInfoOne";
import AlfajiriInfoTwo from "../components/Alfajiri/AlfajiriInfoTwo";

function Alfajiri() {
  return (
    <Fragment>
      <Header />
      <main class="siteContent">
        <div class="jsScroll pos--rel z--3">
          <div class="theBuilder pos--rel">
            <AlfajiriSectionOne />
            <Specification />
            <AlfajiriSectionTwo />
            <AlfajiriVideo />
            <AlfajiriSectionThree />
            <AlfajiriSectionFour />
            <AlfajiriSectionFive />
            <AlfajiriSectionSix />
            <AlfajiriInfoOne />
            <AlfajiriInfoTwo />
            <AlfajiriSectionSeven />
          </div>
          <Footer />
        </div>
      </main>
      <div class="theTransition flex flex--x-center flex--y-center pos--fix z--max o--0">
        <div class="theTransition__loader o--0">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="gridLines pos--fix z--max">
        <div class="gridLines__wrapper flex flex--x-between">
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default Alfajiri;
