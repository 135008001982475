import axios from "axios";
// baseUrl
const BASE_URL = "https://api.buildahome.co.ke/api/v1/";
const shareURL = `${BASE_URL}share`;
const callbackURL = `${BASE_URL}callback`;
const paypalEndpt = "https://api.buildahome.co.ke/api/v1/create-payment";

export const productsEndpt = `${BASE_URL}products`;

async function buyCreation(payload) {
  return await axios.post(shareURL, payload);
}

async function callbackService(payload) {
  return await axios.post(callbackURL, payload);
}

async function postPaypalService(payload) {
  return await axios.post(paypalEndpt, payload);
}

export function validateEmail(email) {
  return email.toLowerCase().includes("gmail");
}

export { buyCreation, callbackService, postPaypalService };
