import alfajiri2 from  "../../assets/images/alfajiri-2.jpg"
function AlfajiriSectionFour() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout2 mt--320 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
          <p>
            I started speaking to older friends and colleagues, who had
            themselves constructed homes, about my desire to build my dream home
            on my new plot. The feedback was almost unanimous.
          </p>
          <span
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
            }}
          >
            Don't do it.
          </span>
          <p>
            The reasons were expressed in different ways but can be traced back
            to another unique Kenyan quirk. We build mansions. We build the
            biggest houses we can afford at the time, even if we don’t really
            need them. This had led the people I was consulting to 4 broad
            categories of issues.
          </p>
          <p>
            1. Building their mansions had tied up capital that they later
            realised they could have invested differently. .
          </p>
        </div>
        <div className="imageText__column col--3">
          <h2 className="imageText__heading fs--40 fw--500 lh--90">
            {/* Get A Plan */}
          </h2>
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                `url(${alfajiri2})`
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default AlfajiriSectionFour;
