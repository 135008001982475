import {Fragment, useState, useEffect} from "react";
import axios from "axios";
import {ReactComponent as Logo} from "../assets/logo.svg";
import Fade from "react-reveal/Fade";
import Modal from "./Modal";
import {productsEndpt} from "../services/create";
import "./hero.css";
import playStoreLogo from "../assets/playstore.jpeg";
import appStoreLogo from "../assets/app_store.jpeg";
import webLogo from "../assets/web.jpeg";
import googleSheetLogo from "../assets/google_sheet.jpeg";


function SectionOneHome() {
    const [showModal, setShowModal] = useState(false);
    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");
    const [amount, setAmount] = useState();

    const handleShowModal = (productId, productName, amount) => {
        setProductId(productId);
        setProductName(productName);
        setAmount(amount);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    // fetch mjengo products function
    const [mjengoProducts, setMjengoProducts] = useState([]);

    const product = mjengoProducts?.find((p) => p.id === 1);
    const fetchMjengoProducts = async () => {
        return await axios.get(productsEndpt);
    };

    useEffect(async () => {
        const {data} = await fetchMjengoProducts();
        setMjengoProducts(data.data[0]);
    }, []);

    return (
        <Fragment>
            <Modal
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                productId={productId}
                productName={productName}
                amount={amount}
            />
            <section className="heroCarousel pos--rel heroCarousel--loading">
                <div
                    data-scroll-section
                    className="heroCarousel__container container flex flex--y-start pos--rel z--2"
                >
                    {/* section-one__custom is added class */}
                    <div className="heroCarousel__left col--3 pos--rel section-one__custom">
                        <div
                            style={{
                                height: "20rem",
                            }}
                        >
                            <Fade left>
                                <Logo width="30rem"/>
                            </Fade>
                        </div>
                        <Fade bottom>
                            <h1
                                className="heroCarousel__header fs--42 fc--white fw--700 lh--90"
                                style={{opacity: "1"}}
                            >
                                Save Your Money Building Your Home
                            </h1>
                        </Fade>
                        <Fade bottom>
                            <h3
                                className="heroCarousel__header fs--20 fc--white fw--500 lh--90"
                                style={{opacity: "1", marginTop: "2rem"}}
                            >
                                Track your Home Construction Project withMjengo Tracker
                            </h3>
                        </Fade>
                    </div>
                    <div className="heroCarousel__right col--3 flex flex--x-center"></div>
                </div>

                <div
                    data-scroll-section
                    className="heroCarousel__tabHolder pos--abs z--3 dis-small"
                    style={{backgroundColor: "white"}}
                >
                    <div className="colourHero__tab">
                        <ul className="app_links">
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=ke.co.buildahome.mjengotracker&hl=en_US"
                                   target="_blank">
                                    <img src={playStoreLogo} alt="product" width="100px"/>
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                   target="_blank">
                                    <img src={appStoreLogo} alt="product" width="100px"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.mjengotracker.com"
                                   target="_blank">
                                    <img src={webLogo} alt="product" width="100px"/>
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                   onClick={() =>
                                       handleShowModal(product.id, product.title, product.price)
                                   }
                                >
                                    <img src={googleSheetLogo} alt="product" width="100px"/>
                                </a>
                            </li>
                        </ul>

                        <div
                            className="linkArrow doubleImage__link "
                            style={{marginTop: "0px", transform: "translateY(0px)"}}
                            onClick={() =>
                                handleShowModal(product.id, product.title, product.price)
                            }
                        >
                            <a className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel">
                                <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 7"
                                        className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                                    >
                                        <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                                    </svg>
                                </div>


                                <p className="linkArrow__label fs--16 fc--orange fw--500">
                                    Get {product?.title}
                                </p>
                                <div className="linkArrow__arrowHolder">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 7"
                                        className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                                    >
                                        <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                                    </svg>
                                </div>
                                <div className="linkArrow__lines">
                                    <div className="linkArrow__overflow flex flex--x-between">
                                        <div className="linkArrow__line"></div>
                                        <div className="linkArrow__line o--3"></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <a
                    href="#"
                    className="heroCarousel__close flex flex--x-center flex--y-center pos--abs z--2"
                >
                    <span></span>
                </a>
                <div className="heroCarousel__carousel pos--abs z--0">
                    <div className="crossfade pos--abs o--0">
                        <figure></figure>
                        <figure></figure>
                        <figure></figure>
                    </div>
                </div>
                <div className="heroCarousel__bg pos--abs"></div>
            </section>
        </Fragment>
    );
}

export default SectionOneHome;
