function Specification() {
  return (
    <section
      id="productDetails"
      data-scroll-section=""
      class="productDetails productDetails--notext mt--160 mb--140 dis-small"
    >
      <div class="productDetails__container container flex flex--y-start">
        <h3 class="productDetails__heading fs--32 fw--500 lh--90 col--2">
          Specification
        </h3>
        <div class="productDetails__details col--3">
          <ul class="productDetails__content flex">
            <li class="productDetails__item col--2">
              <h4 class="productDetails__title fs--12 o--6 fw--400">Budget</h4>
              <p class="productDetails__itemText lh--140">KES. 28M</p>
            </li>
            <li class="productDetails__item col--2">
              <h4 class="productDetails__title fs--12 o--6 fw--400">
                Apartments
              </h4>
              <p class="productDetails__itemText lh--140">4 Apartments</p>
            </li>
            <li class="productDetails__item col--2">
              <h4 class="productDetails__title fs--12 o--6 fw--400">Fence</h4>
              <p class="productDetails__itemText lh--140">Perimeter Fence</p>
            </li>
            <li class="productDetails__item col--2">
              <h4 class="productDetails__title fs--12 o--6 fw--400">
                Driveway
              </h4>
              <p class="productDetails__itemText lh--140">Cabro Driveway</p>
            </li>
            <li class="productDetails__item col--2">
              <h4 class="productDetails__title fs--12 o--6 fw--400">
                Bedrooms
              </h4>
              <p class="productDetails__itemText lh--140">3 - 5 Bedroomes</p>
            </li>
            <li class="productDetails__item col--2">
              <h4 class="productDetails__title fs--12 o--6 fw--400">Water</h4>
              <p class="productDetails__itemText lh--140">
                10K liter overhead water tank <br />
                water purification system
              </p>
            </li>
          </ul>
          <ul class="productDetails__content flex"></ul>
        </div>
      </div>
    </section>
  );
}

export default Specification;
