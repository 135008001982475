import { useState, Fragment, useEffect } from "react";
import axios from "axios";
import Modal from "./Modal";
import Slide from "react-reveal/Slide";

import dashboardImg from "../assets/Dashboards.png";
import { productsEndpt } from "../services/create";

function SectionFourHome() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();

  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // fetch mjengo products function
  const [mjengoProducts, setMjengoProducts] = useState([]);

  const product = mjengoProducts?.find((p) => p.id === 1);
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };

  useEffect(async () => {
    const { data } = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        productId={productId}
        productName={productName}
        amount={amount}
      />
      <section
        data-scroll-section=""
        className="imageGrid bgBlue flex pos--rel z--2 mt--0 mb--200"
      >
        <div className="imageGrid__bigImg pos--rel">
          <div
            data-scroll
            data-scroll-speed="0.5"
            data-scroll-offset="0"
            className="imageGrid__img pos--abs"
            style={{
              backgroundImage: `url(${dashboardImg})`,
              backgroundSize: "contain",
            }}
          ></div>
        </div>
        <div className="imageGrid__contentHolder flex flex--x-between">
          <div className="imageGrid__smallImg pos--rel">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageGrid__img pos--abs"
              style={{
                backgroundImage:
                  'url("https://firebasestorage.googleapis.com/v0/b/baraka-bungalow.appspot.com/o/Header%204-min.jpg?alt=media&token=dea0db19-7181-4232-a64c-6a56392bf70e")',
              }}
            ></div>
          </div>
          <div
            data-scroll
            data-scroll-call="imageGrid"
            className="imageGrid__content"
          >
            <Slide bottom>
              <h2 className="imageGrid__header fs--40 fw--500 lh--90">
                Tracker
              </h2>
            </Slide>
            <Slide bottom>
              <div
                className="imageGrid__text fs--18 fc--grey fw--300 lh--180 "
                style={{ visibility: "visible" }}
              >
                Mjengo Tracker automatically generate graphs, charts and
                dashboard every item estimation cost and actual cost based on
                data entered.
              </div>
            </Slide>
            <Slide bottom>
              <div
                className="linkArrow imageGrid__link "
                style={{ visibility: "visible" }}
              >
                <a
                  className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel"
                  onClick={() =>
                    handleShowModal(product.id, product.title, product.price)
                  }
                >
                  <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <p className="linkArrow__label fs--16 fc--orange fw--500">
                    Get {product?.title}
                  </p>
                  <div className="linkArrow__arrowHolder">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <div className="linkArrow__lines">
                    <div className="linkArrow__overflow flex flex--x-between">
                      <div className="linkArrow__line"></div>
                      <div className="linkArrow__line o--3"></div>
                    </div>
                  </div>
                </a>
              </div>
            </Slide>
          </div>
        </div>
        <div
          data-scroll
          data-scroll-speed="1"
          data-scroll-offset="0"
          className="imageGrid__circle pos--abs z--1"
        ></div>
      </section>
    </Fragment>
  );
}

export default SectionFourHome;
