import alfajiri3 from  "../../assets/images/alfajiri-3.jpg"
function AlfajiriSectionFive() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout1 mt--160 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
            <p>
              2. After a couple of years, they had realised that their concept
              of a dream home had changed.
            </p>
            <p>
              3. Those that wanted to relocate from their mansions found out
              that they couldn’t get tenants to pay what they thought their
              houses were worth in rent. Usually, they were forced to put up
              their houses for rent according to the ‘area average’.
            </p>
            <p>
              4. Once they had built their mansions, it was hard to change their
              properties to something else that wasn’t in the original plan e.g.
              changing a single family home into a multi-dwelling unit.
            </p>
            <p>
              The funny thing is that even though they, almost unanimously,
              advised me against building and said they would do things
              differently if they had another go at it, not a single one of them
              denied the feeling of security and achievement that had come with
              them doing it in the first place.
            </p>
            <p>
              Build because you must but don’t build because you shouldn’t. What
              is a guy supposed to do with such a mess of advice?
            </p>
        </div>
        <div className="imageText__column col--3">
          <h2 className="imageText__heading fs--40 fw--500 lh--90">
            {/* <span style={{ display: "block" }}>When You Gotta Go. </span>
            You Gotta Go */}
          </h2>
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${alfajiri3})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default AlfajiriSectionFive;
