import baraka1 from  "../../assets/images/baraka-1.jpg"
function BarakaSectionThree() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout1 mt--0 mb--0"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{ opacity: 1, visibility: "visible" }}
        >
          <p>
            The second reason for doing it was that, as a child of a single
            mother, I really didn't have anywhere else I could call home. I felt
            a strong desire to establish permanent roots, especially now that I
            had two children of my own.
          </p>
          <p>
            Mom had a small piece of land, less than half an acre, that was left
            to her by her father and we decided, that's where we needed to build
            our family house. It was my first time undertaking such a project.
            Here I was about to invest all my life's savings, KES 3.5M, and I
            honestly didn't know where to begin. I felt out of my depth. I had
            to get a plan.
          </p>
        </div>
        <div className="imageText__column col--3">
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${baraka1})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default BarakaSectionThree;
