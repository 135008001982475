import {useState} from "react";
import {useToasts} from "react-toast-notifications";
import emailjs from "emailjs-com";
import Recaptcha from "react-recaptcha";

import youtubeImg from "../assets/youtube.png";

function Footer() {
    const {addToast} = useToasts();
    const [isVerified, setIsVerified] = useState(false);
    const [state, setState] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    function handleSubmit(e) {
        e.preventDefault();
        emailjs
            .send(
                "service_abaxbtl",
                "template_ki8um0p",
                state,
                "user_g5JL0jUnW8dzXfmEZY1A2"
            )
            .then(
                function (response) {
                    setSuccess("Email Successfully sent. Thank you!");
                },
                function (error) {
                    setError("Could not sent this email. Try later!");
                }
            );
        resetForm();
    }

    function resetForm() {
        setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        });
    }

    function handleChange(e) {
        e.preventDefault();
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    function callback() {
        console.log("Recaptcha Successfully loaded");
    }

    function verifyCallback(response) {
        if (response) setIsVerified(true);
    }

    return (
        <footer data-scroll-section="" className="theFooter">
            <div className="theFooter__container container pos--rel z--1">
                <nav className="theFooter__main flex flex--x-between">
                    <div className="theFooter__contact col--2 no-edge-margin">
                        <p className="theFooter__colTitle fs--14 fc--white fw--500 ls--l30">
                            Get in touch
                        </p>
                        <div className="theFooter__details flex">
                            <a
                                href="mailto:buildahome.ke@gmail.com"
                                className="theFooter__detail fs--14 fc--white fw--500 ls--l30 lh--200 o--4"
                                style={{marginBottom: "1rem"}}
                            >
                                buildahome.ke@gmail.com
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UClTxPKomMLXko3096Ld66Xg"
                                target="_blank"
                            >
                                <img
                                    src={youtubeImg}
                                    alt="Youtube"
                                    width="150"
                                    style={{cursor: "pointer"}}
                                />
                            </a>
                        </div>
                    </div>
                </nav>
                <div className="theFooter__other flex">
                    <form className="contact__right" onSubmit={handleSubmit}>
                        <div className="contact__right--fieldController">
                            <input
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={state.name}
                                onChange={handleChange}
                                required
                                className="contact__right--fieldController--input"
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={state.email}
                                onChange={handleChange}
                                required
                                className="contact__right--fieldController--input"
                            />
                            <input
                                type="text"
                                placeholder="Phone"
                                name="phone"
                                value={state.phone}
                                onChange={handleChange}
                                required
                                className="contact__right--fieldController--input"
                            />
                        </div>
                        <div className="contact__right--fieldController">
                            <input
                                type="text"
                                placeholder="Subject"
                                name="subject"
                                value={state.subject}
                                onChange={handleChange}
                                required
                                className="contact__right--fieldController--input"
                            />
                        </div>
                        <div className="contact__right--fieldController">
              <textarea
                  rows={7}
                  type="text"
                  placeholder="Message"
                  name="message"
                  value={state.message}
                  onChange={handleChange}
                  required
                  className="contact__right--fieldController--input"
              ></textarea>
                        </div>
                        {error && (
                            <p
                                style={{
                                    fontSize: "1.6rem",
                                    color: "tomato",
                                    marginBottom: "2rem",
                                }}
                            >
                                {error}
                            </p>
                        )}
                        {success && (
                            <p
                                style={{
                                    fontSize: "1.2rem",
                                    color: "green",
                                    marginBottom: "2rem",
                                }}
                            >
                                {success}
                            </p>
                        )}
                        <div className="contact__right--fieldController--bottom">
                            {/* <Recaptcha
                sitekey="6Lej6KsaAAAAACjVVChdXntZ-u0wS9mzf_-bSZwf"
                render="explicit"
                onloadCallback={callback}
                verifyCallback={verifyCallback}
              /> */}
                            <button onClick={handleSubmit}>Submit</button>
                        </div>
                    </form>
                    <div className="theFooter__credit">
                        <p className="theFooter__colTitle fs--14 fc--white fw--500 ls--l30"></p>
                    </div>
                </div>
                <div className="theFooter__legal flex">
                    <div className="theFooter__copyrightCol col--2">
                        <p className="theFooter__copyright fs--14 fc--white ls--l40 lh--200 o--4">
                            Copyright © Build A Home 2021
                        </p>
                    </div>
                    <nav className="theFooter__legalNav col--3">
                        <ul className="theFooter__legalItems flex">
                            <li className="theFooter__legalItem">
                                <div className="theFooter__link fs--14 fc--white ls--l40 lh--200 o--4">
                                    Privacy Policy
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="gridLines pos--abs z--2">
                <div className="gridLines__wrapper flex flex--x-between">
                    <div className="gridLines__line gridLines__line--white"></div>
                    <div className="gridLines__line gridLines__line--white"></div>
                    <div className="gridLines__line gridLines__line--white"></div>
                    <div className="gridLines__line gridLines__line--white"></div>
                    <div className="gridLines__line gridLines__line--white"></div>
                    <div className="gridLines__line gridLines__line--white"></div>
                    <div className="gridLines__line gridLines__line--white"></div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
