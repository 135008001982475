import "../Baraka/baraka.css";

function SectionTwoAlfajiri() {
  return (
    <section
      data-scroll-section=""
      className="textOnly textOnly--split50 mt--0 mb--180"
    >
      <div
        data-scroll
        data-scroll-call="textOnly"
        className="textOnly__container container flex flex--y-start"
      >
        <div className="textOnly__header col--3">
          <span
            className="textOnly__icon"
            style={{
              marginBottom: "20px",
              fontWeight: "bolder",
            }}
          >
            The Story
          </span>
          <h3
            className="textOnly__text fs--18 fc--grey fw--400 lh--180"
            style={{ opacity: 1, visibility: "visible" }}
          >
            There is a strange rite of passage that most Kenyan men go through.
            When we get our first jobs, the first thing we’re urged to do is buy
            a piece of land. Parents, uncles, mentors and even those ‘concerned’
            neighbours whose opinions you wouldn’t trust in making even the most
            minor decisions seem to speak in one voice. Nunua ka ploti. This
            mantra is often accompanied by warnings that just because you’re
            making money today, doesn’t mean you’ll make it tomorrow. You’re
            supposed to make hay while the sun shines. Because after all, if you
            have a plot, you’ll always have somewhere to call home, even if it's
            a shack. It will be yours.
          </h3>
        </div>
        <div className="textOnly__column col--3">
          <div
            className="textOnly__text fs--18 fc--grey fw--300 lh--180"
            style={{ opacity: 1, visibility: "visible" }}
          >
            <span
              style={{
                marginBottom: "1rem",
                marginTop: "1rem",
                fontSize: "35px",
                fontWeight: "500",
                color: "black",
              }}
            >
              Nunua Ka Ploti
            </span>
            <p>
              That is how I ended up buying 5 totally useless plots, over the
              course of my first 4 years of employment.
            </p>
            <p>
              I had no idea why I was purchasing these plots. I only knew that I
              had to because of ‘security’. So I bought ‘serviced’ plots in
              ‘controlled developments’ with fancy names like ‘Kitengela
              Sunrise’ that promised thriving communities and instant returns.
              On paper. The reality kwa ground is that those plots look no
              different today, 10 years later, than on the day I purchased them.
              So why this long winded rant about plots? To make a point.
            </p>
          </div>
          <div className="linkArrow textOnly__link">
            <span
              style={{
                marginTop: "1rem",
                fontSize: "35px",
                fontWeight: "500",
                color: "black",
                visibility: "visible",
              }}
            >
              Real Estate Investments
            </span>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionTwoAlfajiri;
