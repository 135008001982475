import baraka4 from  "../../assets/images/baraka-4.jpg"
function BarakaSectionSix() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout2 mt--320 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
          <p>
            The house ended up costing me 3.5M with luxurious tiles, floor and
            roof. In addition to that, I managed to get some more money that I
            could invest in the build. I built a beautiful metal and stone fence
            with finely crafted pillars, a cabro driveway, invested in a fine
            grade, build a 30K litre underground tank connected up 5K litre
            overhead tank, installed a water purification system that could
            filter particles up to 5 microns in size (very fine) that made all
            water coming out of all the taps in the house safe for drinking, and
            build a 20 square meter store and SQ. All this for only 1.5M
            shillings more, which brought my overall project cost to 5M..
          </p>
          <p>
            I had managed to build our dream home for what I had been told was
            the cost of a bungalow with basic finishes. A lot of that success, I
            owe to the fact that the Mjengo tracker enabled me to document,
            track and compare constantly. I was in charge! I hope it helps you
            in your project as well.
          </p>
          <span
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
            }}
          >
            Penye Nia Pana Njia
          </span>
        </div>
        <div className="imageText__column col--3">
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${baraka4})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default BarakaSectionSix;
