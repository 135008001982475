import { Fragment } from "react";
import AboveFooter from "../components/AboveFooter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SectionFourHome from "../components/SectionFourHome";
import SectionOneHome from "../components/SectionOneHome";
import SectionSevenHome from "../components/SectionSevenHome";
import SectionThreeHome from "../components/SectionThreeHome";
import SectionTwoHome from "../components/SectionTwoHome";

function Home() {
  return (
    <Fragment>
      <Header />
      <main class="siteContent">
        <div class="jsScroll pos--rel z--3">
          <div class="theBuilder pos--rel">
            <SectionOneHome />
            <SectionTwoHome />
            <SectionThreeHome />
            <SectionFourHome />
            <AboveFooter />
            <SectionSevenHome />
          </div>
          <Footer />
        </div>
      </main>
      <div class="theTransition flex flex--x-center flex--y-center pos--fix z--max o--0">
        <div class="theTransition__loader o--0">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="gridLines pos--fix z--max">
        <div class="gridLines__wrapper flex flex--x-between">
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
