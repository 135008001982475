function SectionSevenHome() {
  return (
    <section
      data-scroll-section=""
      className="singleCta linkArrow__parent pos--rel mt--0 mb--0"
    >
      <a href="/contact" className="singleCta__holder flex flex--y-center">
        <div className="singleCta__container container pos--rel z--3">
          <div className="singleCta__text">
            <p className="singleCta__pretitle fs--20 fc--white fw--500">
              Share your Project.
            </p>
            <h6 className="singleCta__header fs--36 fc--white fw--500 lh--140">
              Have you used Mjengo Tracker to track your project?
            </h6>
            {/* <div className="linkArrow singleCta__link linkArrow--white">
              <div className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel">
                <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
                <p className="linkArrow__label fs--16 fc--orange fw--500">
                  Get in touch
                </p>
                <div className="linkArrow__arrowHolder">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
                <div className="linkArrow__lines">
                  <div className="linkArrow__overflow flex flex--x-between">
                    <div className="linkArrow__line"></div>
                    <div className="linkArrow__line o--3"></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="singleCta__overlay pos--abs z--2 o--5"></div>
        <div className="singleCta__imgHolder pos--abs z--1">
          <div
            data-scroll=""
            data-scroll-speed="-0.5"
            data-scroll-offset="0"
            className="singleCta__img pos--abs z--1"
            style={{
              backgroundImage:
                'url("https://firebasestorage.googleapis.com/v0/b/baraka-bungalow.appspot.com/o/Header%204-min.jpg?alt=media&token=dea0db19-7181-4232-a64c-6a56392bf70e")',
            }}
          ></div>
        </div>
      </a>
    </section>
  );
}

export default SectionSevenHome;
