import alfajiri1 from  "../../assets/images/alfajiri-1.jpg"
function AlfajiriSectionThree() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout1 mt--0 mb--0"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{ opacity: 1, visibility: "visible" }}
        >
          <span
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
            }}
          >
            Buy for a specific need
          </span>
          <p>
            Investments in real estate must be driven by a purpose unique to
            your specific needs and goals.
          </p>
          <p>
            When I came to this realisation, I swore that my next land purchase
            would be something that would be aligned to the vision I had for my
            future and that of my family and not driven by fear or speculation.
          </p>
          <p>
            So I researched areas that I liked and found a plot that I could
            afford. I put down a deposit and paid off the balance over 6 months.
            I’ll write an article about the process I used to acquire the land
            in Kiambu in a different post. Now that the land was bought and paid
            for, in a beautiful location, the next question was naturally, what
            exactly should I build?
          </p>
        </div>
        <div className="imageText__column col--3">
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${alfajiri1})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default AlfajiriSectionThree;
