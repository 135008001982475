import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { productsEndpt } from "../../services/create";
import Modal from "../Modal";
import b1 from  "../../assets/images/b1.jpg"
import b2 from  "../../assets/images/b2.jpg"
import b3 from  "../../assets/images/b3.jpg"
import b10 from  "../../assets/images/b10.jpg"
import lawn1 from  "../../assets/images/lawn1.jpg"
import lawn2 from  "../../assets/images/lawn2.jpg"
import parking1 from  "../../assets/images/parking1.jpg"
import parking2 from  "../../assets/images/parking2.jpg"

function BarakaSectionSeven() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();

  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // fetch mjengo products function
  const [mjengoProducts, setMjengoProducts] = useState([]);

  const product = mjengoProducts?.find((p) => p.id === 2);
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };

  useEffect(async () => {
    const { data } = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        productId={productId}
        productName={productName}
        amount={amount}
      />
      <section
        id=""
        data-scroll-section=""
        className="products products--interior mt--180 mb--0"
      >
        <div id="productsTop"></div>
        <div
          id="products"
          className="products__container container flex flex--x-between flex--y-start"
        >
          <div
            className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
            style={{
              visibility: "visible",
              opacity: 1,
            }}
          >
            <p
              style={{
                color: "black",
                fontSize: "2.5rem",
                fontWeight: "500",
                borderBottom: "1px solid gray",
              }}
            >
              Baraka Bungalow
            </p>
            <p
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                borderBottom: "1px solid gray",
              }}
            >
              Baraka Bungalow Tracker Features
            </p>
            <p>
              <li>
                See all cost incurred during the development of this project
              </li>
            </p>
            <p>
              <li>
                Have a reference to compare against for quotes made to you on
                your project.
              </li>
            </p>
            <p>
              <li>
                See cost performance on this project vs the estimated costs for
                all phases of the project. View labour and material expenditure
                trends on a week by week basis.
              </li>
            </p>
            <p>
              <li>
                Find out exactly where the money was going when it comes to
                materials purchases.
              </li>
            </p>
            <p>
              <li>
                See how the final actual costs compared to estimates in various
                graphs.
              </li>
            </p>
            <p>
              <li>See the total project duration.</li>
            </p>
            <div
              className="linkArrow doubleImage__link "
              style={{ marginTop: "0px", transform: "translateY(0px)" }}
            >
              <a
                className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel"
                style={{ textDecoration: "none" }}
                onClick={() =>
                  handleShowModal(product.id, product.title, product.price)
                }
              >
                <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
                <p className="linkArrow__label fs--16 fc--orange fw--500">
                  Get {product?.title}
                </p>
                <div className="linkArrow__arrowHolder">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
                <div className="linkArrow__lines">
                  <div className="linkArrow__overflow flex flex--x-between">
                    <div className="linkArrow__line"></div>
                    <div className="linkArrow__line o--3"></div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <ul className="products__results flex flex--x-between col--3">
            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${b1})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${b2})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  {/* <p className="products__label fs--16">System 3400</p> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${parking1})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${parking2})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  {/* <p className="products__label fs--16">System 3400</p> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${lawn2})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${lawn1})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  {/* <p className="products__label fs--16">System 3400</p> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${b3})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${b10})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default BarakaSectionSeven;
