import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import "./baraka.css";

import Modal from "../Modal";
import { productsEndpt } from "../../services/create";
import alfajiriHeader from "../../assets/images/baraka-page-header.jpg"

function SectionOneBaraka() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();

  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // fetch mjengo products function
  const [mjengoProducts, setMjengoProducts] = useState([]);

  const product = mjengoProducts?.find((p) => p.id === 2);
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };

  useEffect(async () => {
    const { data } = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        productId={productId}
        productName={productName}
        amount={amount}
      />
      <section
        data-scroll-section=""
        className="colourHero colourHero--loading bgWhite mt--0 mb--200"
      >
        <div className="container flex pos--rel">
          <div className="colourHero__textCol flex pos--rel z--1">
            <div className="colourHero__contentHolder flex flex--y-center pos--rel">
              <div className="colourHero__content pos--rel z--2">
                <p
                  className="colourHero__pageTitle fs--15 fw--500 ls--l25"
                  style={{
                    opacity: 1,
                    visibility: "visible",
                    fontSize: "1.4rem",
                  }}
                >
                  Baraka Bungalow
                </p>
                <h1
                  className="colourHero__header fs--52 fw--500 lh--150"
                  style={{ opacity: 1, visibility: "visible" }}
                >
                  Up <br /> Country <br /> Living
                </h1>
              </div>
              <div
                className="colourHero__bg pos--abs z--1"
                style={{ backgroundColor: "lightblue" }}
              ></div>
            </div>
            <div className="colourHero__tab">
              <div
                className="linkArrow doubleImage__link "
                style={{ marginTop: "0px", transform: "translateY(0px)" }}
                onClick={() =>
                  handleShowModal(product.id, product.title, product.price)
                }
              >
                <a className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel">
                  <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <p className="linkArrow__label fs--16 fc--orange fw--500">
                    Get {product?.title}
                  </p>
                  <div className="linkArrow__arrowHolder">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <div className="linkArrow__lines">
                    <div className="linkArrow__overflow flex flex--x-between">
                      <div className="linkArrow__line"></div>
                      <div className="linkArrow__line o--3"></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="colourHero__mediaCol pos--abs z--2">
            <a
              href="#"
              className="colourHero__close flex flex--x-center flex--y-center pos--abs z--4"
            >
              <span></span>
            </a>
            <div className="colourHero__topOverlay pos--abs z--3"></div>
            <div className="colourHero__imgOverflow pos--abs z--1">
              <div
                className="colourHero__img"
                style={{
                  backgroundImage:
                    `url(${alfajiriHeader})`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SectionOneBaraka;
