import floorplan from  "../../assets/images/floorplan.png"
function BarakaSectionFour() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout2 mt--320 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
          <p>
            I found a great architect through my network and convinced him to
            give me a simple plan for the bungalow at a low cost. I also
            requested him to introduce me to a contractor who could take care of
            the build for me. I kept hearing that getting a contractor was the
            safest way to go if you were a novice in the construction industry,
            especially if you were not able to supervise the project yourself
            very closely.
          </p>
          <span
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              fontSize: "35px",
              fontWeight: "500",
              color: "black",
            }}
          >
            Fika Bei Baba
          </span>
          <p>
            I met the contractor and explained to him what I wanted, and he
            promptly provided me a quote for the project.
          </p>
          <p>
            5 Million Kenya Shillings for the house only with basic finishes.
          </p>
        </div>
        <div className="imageText__column col--3">
          <h2 className="imageText__heading fs--40 fw--500 lh--90">
            Get A Plan
          </h2>
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${floorplan})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default BarakaSectionFour;
