import { useEffect, createRef } from "react";
import locomotiveScroll from "locomotive-scroll";
import { Route, Switch } from "react-router-dom";
import Baraka from "./pages/Baraka";

import Home from "./pages/Home";
import Alfajiri from "./pages/Alfajiri";

function App() {
  // adding locomotive scroll 
  const scrollRef = createRef();

  useEffect(() => {
    new locomotiveScroll({
      el: scrollRef.current,
      smooth: true,
    });
  }, []);

  return (
    <div ref={scrollRef} className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/baraka" exact component={Baraka} />
        <Route path="/alfajiri" exact component={Alfajiri} />
      </Switch>
    </div>
  );
}

export default App;
