import viewMore from  "../assets/images/view-more.jpg"
import baraka from  "../assets/images/lawn2.jpg"
import alfajiri from  "../assets/images/alfajiri/al12.jpg"
function AboveFooter() {
  return (
    <section
      data-scroll-section=""
      className="multipleCta mt--0 mb--0"
      style={{
        paddingBottom: "5rem",
        backgroundColor: "lightgray",
      }}
    >
      <ul className="multipleCta__list flex flex--r-nowrap">
        <li className="multipleCta__item pos--rel">
          <a href="/baraka" className="multipleCta__link pos--rel">
            <div
              className="multipleCta__image pos--abs"
              style={{
                backgroundImage:
                  `url(${baraka})`,
              }}
            ></div>
            <p className="multipleCta__label pos--abs z--3 fs--48 fc--white center--xy fw--400">
              Baraka Bungalow
            </p>
          </a>
        </li>
        <li className="multipleCta__item pos--rel">
          <a href="/alfajiri" className="multipleCta__link pos--rel">
            <div
              className="multipleCta__image pos--abs"
              style={{
                backgroundImage:
                  `url(${alfajiri})`,
              }}
            ></div>
            <p className="multipleCta__label pos--abs z--3 fs--48 fc--white center--xy fw--400">
              Alfajiri Apartments
            </p>
          </a>
        </li>
        <li className="multipleCta__item pos--rel">
          <a to="/" className="multipleCta__link pos--rel">
            <div
              className="multipleCta__image pos--abs"
              style={{
                backgroundImage:
                  `url(${viewMore})`,
              }}
            ></div>
            <p className="multipleCta__label pos--abs z--3 fs--48 fc--white center--xy fw--400">
              View More
            </p>
          </a>
        </li>
      </ul>
    </section>
  );
}

export default AboveFooter;
