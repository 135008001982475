import baraka5 from  "../../assets/images/baraka-5.jpg"
function BarakaSectionFive() {
  return (
    <section
      data-scroll-section=""
      className="imageText pos--rel imageText--layout1 mt--160 mb--160"
    >
      <div
        data-scroll
        data-scroll-call="imageText"
        className="imageText__container container flex flex--r-nowrap flex--x-between flex--y-start"
      >
        <div
          className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
          style={{
            visibility: "visible",
            opacity: 1,
          }}
        >
          <p>
            This was above my budget. I only had 3.5M to spend. I thanked him
            for the quote and realized I had no choice but to go my plan B. See,
            I had also heard that one could achieve great savings by finding a
            'FUNDI' and doing a self build without the services of a contractor.
            I felt apprehensive but excited by the challenge.
          </p>
          <p>
            Through family connections in 'shagz' I managed to find a fundi and
            bring him on board for the job in August. I used the Mjengo Tracker
            to track every single coin I was spending. It made me feel in charge
            of the project.
          </p>
          <p>
            To my very pleasant surprise, I realized that I was getting way more
            bang for my buck than had been quoted by the contractor. Even though
            I was only able to go to the site once a week, I was pleased to see
            that the build team that my foreman had assembled was doing a great
            job. In addition to that, my half brother, who had some building
            experience, also joined the build team and was really assisting in
            ensuring everything was staying on track.
          </p>
        </div>
        <div className="imageText__column col--3">
          <h2 className="imageText__heading fs--40 fw--500 lh--90">
            <span style={{ display: "block" }}>When You Gotta Go. </span>
            You Gotta Go
          </h2>
          <div className="imageText__imageHolder imageText__imageHolder--1 pos--rel z--2 imageText__imageHolder--small">
            <div
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-offset="0"
              className="imageText__image pos--abs"
              style={{
                backgroundImage:
                  `url(${baraka5})`,
              }}
            ></div>
          </div>
          <div className="imageText__otherImages pos--rel z--2"></div>
        </div>
      </div>
    </section>
  );
}

export default BarakaSectionFive;
