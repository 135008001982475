function AlfajiriVideo() {
  return (
    <section
      id="virtualtour"
      data-scroll-section=""
      class="virtualTour mt--0 mb--180"
    >
      <div class="virtualTour__container container">
        <h3 class="virtualTour__heading fs--24 fw--500 lh--90">
          View Baraka Bungalow Building process and story behind it.
        </h3>
        <iframe
          class="virtualTour__iframe"
          width="1905"
          height="770"
          src="https://www.youtube.com/embed/8H4Za4zUP-g?rel=0;controls=0;showInfo=0"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
  );
}

export default AlfajiriVideo;
