import { Fragment } from "react";
import BarakaSectionFive from "../components/Baraka/BarakaSectionFive";
import BarakaSectionFour from "../components/Baraka/BarakaSectionFour";
import BarakaSectionSeven from "../components/Baraka/BarakaSectionSeven";
import BarakaSectionSix from "../components/Baraka/BarakaSectionSix";
import BarakaSectionThree from "../components/Baraka/BarakaSectionThree";
import BarakaVideo from "../components/Baraka/BarakaVideo";
import SectionOneBaraka from "../components/Baraka/SectionOneBaraka";
import SectionTwoBaraka from "../components/Baraka/SectionTwoBaraka";
import Specification from "../components/Baraka/Specification";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Baraka() {
  return (
    <Fragment>
      <Header />
      <main class="siteContent">
        <div class="jsScroll pos--rel z--3">
          <div class="theBuilder pos--rel">
            <SectionOneBaraka />
            <Specification />
            <SectionTwoBaraka />
            <BarakaVideo />
            <BarakaSectionThree />
            <BarakaSectionFour />
            <BarakaSectionFive />
            <BarakaSectionSix />
            <BarakaSectionSeven />
          </div>
          <Footer />
        </div>
      </main>
      <div class="theTransition flex flex--x-center flex--y-center pos--fix z--max o--0">
        <div class="theTransition__loader o--0">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="gridLines pos--fix z--max">
        <div class="gridLines__wrapper flex flex--x-between">
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
          <div class="gridLines__line"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default Baraka;
