import { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { productsEndpt } from "../../services/create";
import al1 from  "../../assets/images/alfajiri/al1.jpg"
import al2 from  "../../assets/images/alfajiri/al2.jpg"
import al3 from  "../../assets/images/alfajiri/al3.jpg"
import al4 from  "../../assets/images/alfajiri/al4.jpg"
import al5 from  "../../assets/images/alfajiri/al5.jpg"
import al6 from  "../../assets/images/alfajiri/al6.jpg"
import al7 from  "../../assets/images/alfajiri/al7.jpg"
import al8 from  "../../assets/images/alfajiri/al8.jpg"
import al9 from  "../../assets/images/alfajiri/al9.jpg"
import al10 from  "../../assets/images/alfajiri/al10.jpg"
import al11 from  "../../assets/images/alfajiri/al11.jpg"
import al12 from  "../../assets/images/alfajiri/al12.jpg"

import Modal from "../Modal";

function AlfajiriSectionSeven() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();

  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // fetch mjengo products function
  const [mjengoProducts, setMjengoProducts] = useState([]);

  const product = mjengoProducts?.find((p) => p.id === 3);
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };

  useEffect(async () => {
    const { data } = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        productId={productId}
        productName={productName}
        amount={amount}
      />
      <section
        id=""
        data-scroll-section=""
        className="products products--interior mt--180 mb--0"
      >
        <div id="productsTop"></div>
        <div
          id="products"
          className="products__container container flex flex--x-between flex--y-start"
        >
          <div
            className="imageText__text fs--18 fc--grey fw--300 lh--180 col--3"
            style={{
              visibility: "visible",
              opacity: 1,
            }}
          >
            <p>
              6. Because of the point above, I found it worked best for me to
              have skilled labour on site that was paid on a daily basis as
              opposed to subcontractors or a labour contract paid in arrears for
              each completed milestone e.g. each steel fabricated door costs
              3,000.
            </p>
            <p>
              7. Your move in timeline is going to go out of the window. We
              expected to move into the house 6 months after construction began
              but by the time we were meant to move in, we had barely finished
              the structure.
            </p>
            <p>
              8. Get at least 3 quotes for everything. This served me well
              because I ended up making some significant savings for some things
              by not going with the first or easiest available option.
            </p>
            <p>
              9. Incompetent labour can cost you a lot. Some of the labourers on
              my site who didn’t really know what they were doing ended up
              causing damages to completed work e.g. cutting through slabs. Vet
              your labour thoroughly!.
            </p>
            <p>
              10. Conflict will occur during the project with various players,
              and it’s not necessarily a bad thing. Over the course of the
              project, I disagreed to varying degrees with my Engineer, Foreman
              and some subcontractors. Some of these conflicts ended up creating
              stronger bonds and respect for each other once they were resolved.
            </p>
            <p
              style={{
                color: "black",
                fontSize: "2rem",
                fontWeight: "500",
                borderBottom: "1px solid gray",
                marginTop: "2rem",
              }}
            >
              Alfajiri Apartments
            </p>
            <p
              style={{
                fontSize: "1.8rem",
                fontWeight: "500",
                borderBottom: "1px solid gray",
              }}
            >
              Alfajiri Apartments Tracker Features
            </p>
            <p>
              <li>
                See all costs incurred during the development of this project
              </li>
            </p>
            <p>
              <li>
                Have a reference to compare against for quotes made to you on
                your project.
              </li>
            </p>
            <p>
              <li>
                See cost performance on this project vs the estimated costs for
                all phases of the project.
              </li>
            </p>
            <p>
              <li>
                View labour and material expenditure trends on a week by week
                basis.
              </li>
            </p>
            <p>
              <li>
                Find out exactly where the money was going when it comes to
                materials purchases.
              </li>
            </p>
            <p>
              <li>
                See how the final actual costs compared to estimates in various
                graphs.
              </li>
            </p>
            <div
              className="linkArrow textOnly__link"
              style={{ marginTop: "0px", transform: "translateY(0px)" }}
            >
              <a
                className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel"
                onClick={() =>
                  handleShowModal(product.id, product.title, product.price)
                }
                style={{
                  opacity: 1,
                  visibility: "visible",
                  textDecoration: "none",
                }}
              >
                <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
                <p className="linkArrow__label fs--16 fc--orange fw--500">
                  Get {product?.title}
                </p>
                <div className="linkArrow__arrowHolder">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
                <div className="linkArrow__lines">
                  <div className="linkArrow__overflow flex flex--x-between">
                    <div className="linkArrow__line"></div>
                    <div className="linkArrow__line o--3"></div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <ul className="products__results flex flex--x-between col--3">
            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${al2})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${al1})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${al3})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${al4})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${al5})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${al6})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${al7})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${al8})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${al9})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${al10})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>

            <li
              data-type="Mullion Free"
              data-material="Aluminium"
              className="products__item"
            >
              <div className="products__itemLink">
                <div className="products__imageHolder pos--rel">
                  <div
                    className="products__image products__image--1 pos--abs"
                    style={{
                      backgroundImage:
                        `url(${al11})`,
                    }}
                  ></div>
                  <div
                    className="products__image products__image--2 pos--abs z--2 o--0"
                    style={{
                      backgroundImage:
                        `url(${al12})`,
                    }}
                  ></div>
                </div>
                <div className="products__labelHolder flex flex--r-nowrap flex--y-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 7"
                    fill="#1B1C21"
                    className="arrow arrow--right products__arrow"
                  >
                    <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default AlfajiriSectionSeven;
