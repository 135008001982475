import { useState, Fragment, useEffect } from "react";
import { productsEndpt } from "../services/create";
import axios from "axios";
import Modal from "./Modal";
import laptopAndPhone from  "../assets/images/laptop-and-phone.jpg"

function SectionThreeHome() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();

  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // fetch mjengo products function
  const [mjengoProducts, setMjengoProducts] = useState([]);

  const product = mjengoProducts?.find((p) => p.id === 1);
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };

  useEffect(async () => {
    const { data } = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);

  return (
    <Fragment>
      <Modal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        productId={productId}
        productName={productName}
        amount={amount}
      />
      <section
        data-scroll-section=""
        className="doubleImage bgWhite doubleImage--flipped mt--0 mb--280"
      >
        <div className="doubleImage__container container flex flex--y-center custom-container">
          <div className="doubleImage__textCol col--3 custom-col">
            <div
              data-scroll
              data-scroll-call="doubleImage"
              className="doubleImage__textHolder"
            >
              <p className="doubleImage__pretitle fs--16 fw--500 lh--90 ">
                Insights
              </p>
              <h2 className="doubleImage__header fs--48 fw--500 lh--90">
                Mjengo Tracker Features
              </h2>
              <div className="doubleImage__text fs--18 fc--grey fw--300 lh--180 ">
                <li>Track overall project cost easily.</li>
                <li>
                  View labour and material expenditure trends on a week by week
                  basis.
                </li>
                <li>
                  Compare costs for various categories of expenditures easily
                  e.g. material cost vs labour cost.
                </li>
                <li>Compare actual costs vs estimates in various graphs</li>
                <li>
                  Graphs and Dashboards showing how your project is going are
                  automatically generated whenever you enter your data
                </li>
              </div>
              <div className="linkArrow doubleImage__link ">
                <a
                  className="linkArrow__link flex flex--inline flex--x-between flex--y-center pos--rel"
                  onClick={() =>
                    handleShowModal(product?.id, product?.title, product?.price)
                  }
                >
                  <div className="linkArrow__arrowHolder linkArrow__arrowHolder--before pos--abs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--1"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <p className="linkArrow__label fs--16 fc--orange fw--500">
                    Get {product?.title}
                  </p>
                  <div className="linkArrow__arrowHolder">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 7"
                      className="arrow arrow--right linkArrow__arrow linkArrow__arrow--2 o--5"
                    >
                      <path d="M20,3.51,13.85,7l0-3H0V3H13.83V0Z"></path>
                    </svg>
                  </div>
                  <div className="linkArrow__lines">
                    <div className="linkArrow__overflow flex flex--x-between">
                      <div className="linkArrow__line"></div>
                      <div className="linkArrow__line o--3"></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="doubleImage__imgCol col--3 custom-col">
            <div
              className="doubleImage__singleImg"
              style={{
                backgroundImage:
                  `url(${laptopAndPhone})`,
              }}
            ></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SectionThreeHome;
