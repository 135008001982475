import {useState, Fragment, useEffect} from "react";
import axios from "axios";
import Modal from "./Modal";
import Popup from "reactjs-popup";
import useWindowDimensions from "../hooks/useWindowDimensions";

import bigLogo from "../assets/bigLogo.png";
import {productsEndpt} from "../services/create";

function Header() {
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState();
  const [mjengoProducts, setMjengoProducts] = useState([]);

  // fetch mjengo products function
  const fetchMjengoProducts = async () => {
    return await axios.get(productsEndpt);
  };
  useEffect(async () => {
    const {data} = await fetchMjengoProducts();
    setMjengoProducts(data.data[0]);
  }, []);


  const handleShowModal = (productId, productName, amount) => {
    setProductId(productId);
    setProductName(productName);
    setAmount(amount);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const [whiteNav, setWhiteNav] = useState(true);

  const changeNavBackground = () => {
    if (window.scrollY >= 80) setWhiteNav(true);
    else setWhiteNav(false);
  };

  window.addEventListener("scroll", changeNavBackground);

  const {width} = useWindowDimensions();

  let menu = false;
  if (width < 1000) {
    menu = true;
  }

  return (
      <Fragment>
        <Modal
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            productId={productId}
            productName={productName}
            amount={amount}
        />
        <header
            class="theHeader flex flex--x-between flex--y-center pos--fix z--max"
            style={whiteNav ? {backgroundColor: "white"} : {}}
        >
          <a
              href="/"
              class="theHeader__logo theHeader__logo--main pos--abs z--1 nuxt-link-active"
              style={{
                width: "12rem",
                marginLeft: "12.5vw",
              }}
          >
            <img src={bigLogo} alt="Logo" width="200px"/>
          </a>
          <a
              href="/"
              class="theHeader__logo theHeader__logo--subnav pos--rel z--4 o--0 nuxt-link-active"
          >
            <img src={bigLogo} alt="Logo" width="150px"/>
          </a>
          <div
              class="theHeader__right flex pos--rel z--4"
              style={{transform: "translate(0px, 0px"}}
          >
            <ul class="theHeader__nav flex pos--rel z--3">
              <li class="theHeader__navItem">
                <Popup
                    trigger={
                      <div
                          class="theHeader__navLink flex flex--x-center flex--y-center pos--rel"
                          style={{cursor: "pointer"}}
                      >
                        <div className="theHeader__navLinkLabel fs--13 fw--500">
                          <p> Get Trackers </p>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 8.88 4.9"
                            fill="#1B1C21"
                            class="theHeader__navLinkCaret o--5"
                        >
                          <path
                              d="M4.44,4.9l.38-.34L8.88.92,8.13,0,4.44,3.3.75,0,0,.92,4.07,4.56Z"
                              fill="#1b1c21"
                          ></path>
                        </svg>
                      </div>
                    }
                    position="bottom center"
                    on="hover"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    contentStyle={{border: "none"}}
                    arrow={false}
                >
                  <div className="top__menu">
                    {mjengoProducts.map((product, i) => (
                        <div
                            className="top__menu-item"
                            onClick={() =>
                                handleShowModal(
                                    product.id,
                                    product.title,
                                    product.price
                                )
                            }
                            key={i}
                        >
                          {product.title}
                        </div>
                    ))}
                  </div>
                </Popup>
              </li>
              <li class="theHeader__navItem">
                <Popup
                    trigger={
                      <div
                          class="theHeader__navLink flex flex--x-center flex--y-center pos--rel"
                          style={{cursor: "pointer"}}
                      >
                        <div className="">
                          <p className="theHeader__navLinkLabel fs--13 fw--500">
                            View Mjengo Projects
                          </p>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 8.88 4.9"
                            fill="#1B1C21"
                            class="theHeader__navLinkCaret o--5"
                        >
                          <path
                              d="M4.44,4.9l.38-.34L8.88.92,8.13,0,4.44,3.3.75,0,0,.92,4.07,4.56Z"
                              fill="#1b1c21"
                          ></path>
                        </svg>
                      </div>
                    }
                    position="bottom center"
                    on="hover"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    contentStyle={{
                      border: "none",
                    }}
                    arrow={false}
                >
                  <div className="top__menu">
                    <a
                        href="/baraka"
                        className="top__menu-item"
                        style={{outline: "none"}}
                    >
                      Baraka Bungalow
                    </a>
                    <a href="/alfajiri" className="top__menu-item">
                      Alfajiri Apartments
                    </a>
                  </div>
                </Popup>
              </li>
              <li class="theHeader__navItem">
                <Popup
                    trigger={
                      <div
                          class="theHeader__navLink flex flex--x-center flex--y-center pos--rel"
                          style={{cursor: "pointer"}}
                      >
                        <div className="theHeader__navLinkLabel fs--13 fw--500">
                          <p> Mjengo Store </p>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 8.88 4.9"
                            fill="#1B1C21"
                            class="theHeader__navLinkCaret o--5"
                        >
                          <path
                              d="M4.44,4.9l.38-.34L8.88.92,8.13,0,4.44,3.3.75,0,0,.92,4.07,4.56Z"
                              fill="#1b1c21"
                          ></path>
                        </svg>
                      </div>
                    }
                    position="bottom center"
                    on="hover"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    contentStyle={{border: "none"}}
                    arrow={false}
                >
                  <div className="top__menu">
                    <div className="top__menu-item">
                      <a
                          href="https://www.mjengotracker.com"
                          target="_blank"
                          className="top__menu-item"
                          style={{outline: "none"}}
                      >
                        Visit Store
                      </a>
                    </div>
                  </div>
                </Popup>
              </li>
            </ul>
            {!menu && (
                <a
                    href="#"
                    class="theHeader__menuBtn flex flex--x-center flex--y-center pos--rel z--2"
                >
                  <p class="theHeader__menuBtnLabel fs--13 fw--500 pos--abs center--xy o--0">
                    Menu
                  </p>
                  <div class="theHeader__menuBtnIconHolder no-edge-margin">
                    <span class="theHeader__menuBtnLine theHeader__menuBtnLine--1"></span>
                    <span class="theHeader__menuBtnLine theHeader__menuBtnLine--2"></span>
                  </div>
                </a>
            )}
          </div>
          <div class="theHeader__bg pos--abs z--2"></div>
          <div class="theHeader__overlay pos--fix z--1 o--0"></div>
        </header>
      </Fragment>
  );
}

export default Header;